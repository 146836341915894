import React from 'react'
import { graphql } from 'gatsby'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import Project from '../../components/project'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'


const ProjectTemplate = props => {
  const { data, errors } = props
  const project = data && data.project

  // Grab Header image copy, else grab header copy in body.
  const descriptionMessage = () => {
    if(project.headerImageMessage){
      return  project.headerImageMessage;
    }else{
      for (const ele of project._rawBody) {
        if(ele.style==="h1"){
          return ele.children[0].text;
          break;
        }
      };
      return '';
    }
  }
  return (

    <Layout site='Adelaide'>
      {errors && <SEO title='GraphQL Error' />}
      {project && <SEO
        title={`${project.client} - ${project.title}`|| ''}
        description={descriptionMessage()}
        keywords={[project.client]}
      />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project  site='Adelaide' {...project} />}

    </Layout>
  )
}

export default ProjectTemplate



export const query = graphql`
  query ProjectAdelaideTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      relatedProjects {
        title
        client
        _id
        slug {
          current
        }
        mainImage {
          asset {
            _id
            fluid(maxWidth:800, maxHeight:450){
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      headerImageMessage
      mainImage {
        asset {
          _id
          fluid(maxWidth:2560){
            ...GatsbySanityImageFluid
          }
        }
      }
      title
      client
      slug {
        current
      }
      _rawBody
      impactArray{
        _key
        figure
        from
      }
      darkMode
      headerImageMessage
      headerImageMessageBlack
      headerImageMessageCenter
      pageGradientC1
    }
  }
`


