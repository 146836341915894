import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import BlockContent from './block-content'
import ProjectPreview from './project-preview'
import useRealHeight from '../lib/use-real-height'
import { cn } from '../lib/helpers'
import Img from 'gatsby-image'


import styles from './project.module.css'


const Project = (props) => {

  const { site, _rawBody, title, client, impactArray, mainImage, relatedProjects, headerImageMessage, darkMode, headerImageMessageBlack, headerImageMessageCenter, pageGradientC1 } = props
  useRealHeight();
  const scrollToo = React.createRef(null);
  const repeatScrollerMessage = 32; 

  let applyGradient = false;
  const pageGradCheck = () =>{
    if(!darkMode && (pageGradientC1)!=(null,"", undefined) ){
      applyGradient = true;
    }
  }

  function hexToRGB(hex, alpha = 1) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"; 
  }
 
  const scrollDown = () => {
    const offset = window.innerWidth >= 530 ? 0 : 50  ; //offset mobile navbar
    const point =  scrollToo.current.offsetTop - offset;
    window.scrollTo({top:point, behavior: 'smooth'});
  }
  
  pageGradCheck()
  const pageScroll = (evt) =>{
    if(!scrollToo.current) return;
    if(!pageGradientC1) return; // HACK: Add this line to temporarily avoid error of null at end of function TODO: Fix

    let curPos = document.scrollingElement.scrollTop;
    let windowHeight = document.scrollingElement.clientHeight;
    let eleHeight = scrollToo.current.scrollHeight;
    let eleOffsetHeight = scrollToo.current.offsetTop;
    let eleAndBrowserHeight = (eleHeight <= windowHeight) ? windowHeight - eleHeight : 0;
    let alphaLevel = 0 ;

    let calc1 = curPos - (eleOffsetHeight - eleAndBrowserHeight)
    let calc2 = Math.abs(eleHeight - eleOffsetHeight)
    if(calc2<=150) calc2 = 150;

    alphaLevel = calc1 / calc2  * 1
    if(alphaLevel<=0) alphaLevel = 0;
    if(alphaLevel>=1) alphaLevel = 1;

    scrollToo.current.style.backgroundColor = hexToRGB(pageGradientC1, 1-alphaLevel)
  }

  useEffect(() => {
    document.addEventListener('scroll', pageScroll);
    return() =>{
      document.removeEventListener('scroll', pageScroll);
    }
  }, [])


  let impactItemClassName = `impactItem${impactArray.length}`
  return (
    
    <div className={`${styles.root}`}>
        {props.mainImage && mainImage.asset && (
          <div className={styles.mainImage}>
              <Img 
                className={styles.image} 
                alt={`Main image for ${title}`} 
                fluid={props.mainImage.asset.fluid}  
                objectFit 
              />
            <div 
            className={`
              ${styles.headerOverlay} 
              ${headerImageMessageCenter ? styles.headerCenter :""}
              ${headerImageMessageBlack ? styles.black :""} 
            `}>
              <p className={styles.headerImageMessage}>{headerImageMessage}</p>
              <div className={styles.arrow} onClick={scrollDown} ></div>
            </div>
          </div>
        )}
        
       <div className={styles.contentWrap}>
        <div 
          className={`${styles.contentRoot} ${styles[site]} ${darkMode ? styles.darkMode : "" }`}
          ref={scrollToo} 
          style={ applyGradient ? {backgroundColor: pageGradientC1} : null } >
          {/* 
            //Client and Project name scroller
            Disabled incase they changed their minds again 
          <div className={styles.container}>
              <span className={cn(styles.message, styles.hide)} >{client}</span>
              <div className={styles.scroll}>
                {[...Array(repeatScrollerMessage)].map((e, i) => 
                  <span className={styles.message} key={i}>
                    { (i % 2) ? client : title}
                  </span>
                )}
            </div>
          </div> 
          */}

          <article>
            <div className={styles.mainContent}>
              {_rawBody && <BlockContent blocks={_rawBody || []} />}
            </div>

          </article>

          { impactArray[0] && (
            <div className={`${styles.impactSection} ${styles[site]}`}>
              <ul className={`${styles.impactList} ${styles[site]}`}>
                {impactArray.map(item => (
                  <li key={item._key} className={`${styles.impactItem} ${styles[impactItemClassName]} ${styles[site]}`}>
                    <span className={styles.figure}>{item.figure}</span>
                    <span className={styles.from}>{item.from}</span>
                  </li>
                ))}
              </ul>

            </div>
          )}

        </div>
      </div>
      <div className={`${styles.contentRoot}`}>

        <Link to={'../'} className={`${styles.moreWork} ${styles[site]}`}>
          <h6 className={`${styles.smallHeader} ${styles.relatedProjectsHeadline}`}> MORE WORK </h6>
        </Link>

        {relatedProjects[0] && (
          <div className={`${styles.relatedProjects} ${styles[site]}`}>
            <ul> 
              {relatedProjects &&
                relatedProjects.map((node, i) => {
                  if(i >= 2) return(null);
                  return(
                  <li key={`${node._id}${i}`}>
                    <ProjectPreview {...node} />
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>


    </div>

  )
}


export default Project